// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
require("bootstrap")
require("selectize")
require("packs/jquery.easy-autocomplete");
require("packs/cocoon")
require("packs/artwork")
require("packs/autonumeric")
require("packs/direct_uploads")
require("packs/person")
require("packs/exhibitions")
require("packs/literatures")
require("packs/images")
require("packs/editions")
require("packs/sales")
require("packs/search")
require("packs/google_analytics")
require("packs/pdfs")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbolinks:load', function() {

  if ( $('[type="date"]').prop('type') != 'date' ) {
    $('[type="date"]').datepicker({
      autoClose: true,
      todayHighlight: true,
    });
  }

  $('.disable-submit').on('keypress', e => {
      if (e.keyCode == 13) {
          return false;
      }
  });

  $(document).ready(function () {
        $('.dropdown-toggle').dropdown();
    });

  $(".alert-success").fadeTo(2000, 500).slideUp(500, function(){
    $(".alert-success").slideUp(500);
  });

});
