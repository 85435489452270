$(document).on("turbolinks:load", function() {

  $(".artwork-pdf-btn").on('click', function() {
    const params = {
      imgs: [],
      edition: [],
      exhibs: [],
      lits: [],
      provs: [],
    }
    const link = $("#create-pdf-btn")[0].href

    $(".artwork_select_details_modal").modal('show');

    $(".img-check-box").on('click', function () {
      if ($(this).is(":checked")) {
        params.imgs.push(this.value)
        updateLink(params, link)
      } else {
        params["imgs"] = params["imgs"].filter(item => item !== this.value)
        updateLink(params, link)
      }
    })

    $(".prov-check-box").on('click', function () {
      if ($(this).is(":checked")) {
        params.provs.push(this.value)
        if ($(this).id != "undefined") {
          params.edition.push(this.id)
        }
        updateLink(params, link)
      } else {
        params["provs"] = params["provs"].filter(item => item !== this.value)
        if ($(this).id != "undefined") {
          params["edition"] = params["edition"].filter(item => item !== this.value)
        }
        updateLink(params, link)
      }
    })

    $(".exhib-check-box").on('click', function () {
      if ($(this).is(":checked")) {
        params.exhibs.push(this.value)
        updateLink(params, link)
      } else {
        params["exhibs"] = params["exhibs"].filter(item => item !== this.value)
        updateLink(params, link)
      }
    })

    $(".lit-check-box").on('click', function () {
      if ($(this).is(":checked")) {
        params.lits.push(this.value)
        updateLink(params, link)
      } else {
        params["lits"] = params["lits"].filter(item => item !== this.value)
        updateLink(params, link)
      }
    })

  function updateLink(params, link) {
    const keys = Object.keys(params)
    for (const key of keys) {
      if (params[key].length > 0 ) {
        if (link.indexOf("?") === -1) {
          link += "?"
        }
        if (params[key].length == 1) {
          link += key + "=" + params[key] + "&"
        } else {
          params[key].forEach(function(item, index) {
            link += key + "%5B%5D=" + item + "&"
          })
        }
      }
    }
    console.log(link)
    $("#create-pdf-btn").attr("href", link)

  }

  });

});
