$(document).on("turbolinks:load", function() {

  //selectize params

  var selectizeDateParams = {
    maxItems: '1',
  }

  var selectizeCallback = null;

  var selectizeOrgExhibitionParams = {
      persist: true,
      placeholder: "Select organisation",
      sortField: 'text',
  }

  var selectizeExhibitionParams = {
    persist: true,
    placeholder: "Select exhibition or type to add a new one",
    sortField: 'text',
    create: function(input,callback) {
      selectizeCallback = callback;
      $.ajax({
        method: "POST",
        url: "/exhibitions/",
        data: {exhibition: {name: input}},
        success: function(response) {
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
        }
      });
    }
  };

  // on startup

  $(".selectize-exhibit-start-date").selectize(selectizeDateParams)
  $(".selectize-exhibit-end-date").selectize(selectizeDateParams)
  $(".selectize-exhibition").selectize(selectizeExhibitionParams);
  var items = document.querySelectorAll(".selectize-exhibition")
  for (var i=0; i < items.length; i+= 3) {
    var theItem = items[i].selectize
    theItem.clearOptions()
    theItem.load(function(callback){
      getAllExhibits(callback)
    })
  };

  function getAllExhibits(callback) {
    $.ajax({
      method: "GET",
      url: "/exhibitions",
      success: function(response) {
        callback(response)
      },
      error: function() {
        console.log(error);
        callback();
      }
    })
  }

  // adding new exhibitions

  $('#artwork_exhibitions').on('cocoon:after-insert', function(e, addedItem) {
    console.log(addedItem)
    var newExhibit = $(addedItem).find($(".selectize-exhibition")).selectize(selectizeExhibitionParams);
    var newExhibitOrg = $(addedItem).find($(".selectize-org-exhibition")).selectize(selectizeOrgExhibitionParams);
    var newExhibitStartDate = $(addedItem).find($(".selectize-exhibit-start-date")).selectize({maxItems: '1'});
    var newExhibitEndDate = $(addedItem).find($(".selectize-exhibit-end-date")).selectize({maxItems: '1'});

    var exhibit = newExhibit[0].selectize;
    var exhibitOrg = newExhibitOrg[0].selectize;
    var exhibitStartMonth = newExhibitStartDate[0].selectize;
    var exhibitStartYear = newExhibitStartDate[1].selectize;
    var exhibitEndMonth = newExhibitEndDate[0].selectize;
    var exhibitEndYear = newExhibitEndDate[1].selectize;
    var exhibitID = $(addedItem).find($(".exhibit-id"));
    var exhibitName = $(addedItem).find($(".exhibit-name"));

    exhibit.clearOptions();
    exhibit.load(function(callback){
      getAllExhibits(callback)
    });

    exhibitOrg.clearOptions();
    exhibitOrg.load(function(callback) {
      $.ajax({
        method: "GET",
        url: "/organisations",
        success: function(response) {
          callback(response)
        }
      });
    });

    $('.disable-submit').on('keypress', e => {
        if (e.keyCode == 13) {
            return false;
        }
    });

    $(addedItem).find($(".selectize-exhibition")).on("change", function() {
      getExhibitDetails(exhibit).then(function(result) {
        setExhibitDetails(result, exhibit, exhibitOrg, exhibitStartMonth, exhibitStartYear, exhibitEndMonth, exhibitEndYear, exhibitID, exhibitName);
      })
    })
  })

  //on change of exhibition

  var allExhibits = document.querySelectorAll(".exhibit")
  var theDivs = []
  for (var i=0; i < allExhibits.length; i++) {
    theDivs.push(allExhibits[i])
  }

  $(".selectize-exhibition").on("change", function(e) {
    var exhibit = null
    var startDate = null
    var endDate = null
    var exhibitOrg = null
    var exhibitStartMonth = null
    var exhibitStartYear = null
    var exhibitEndMonth = null
    var exhibitEndYear = null
    var exhibitID = null
    var exhibitName = null;
    for (var i=0; i < theDivs.length; i++ ) {
      if (e.target.name == $(theDivs[i]).find(".selectize-exhibition")[0].name) {
        var div = theDivs[i]
        var settingTheDiv = new Promise(
          function(resolve, reject) {
            if (div != null) {
            exhibit = div.querySelector(".selectize-exhibition").selectize
            startDate = div.querySelectorAll(".selectize-exhibit-start-date")
            endDate = div.querySelectorAll(".selectize-exhibit-end-date")
            exhibitOrg = div.querySelector(".selectize-org-exhibition").selectize;
            exhibitStartMonth = startDate[0].selectize;
            exhibitStartYear = startDate[3].selectize;
            exhibitEndMonth = endDate[0].selectize;
            exhibitEndYear = endDate[3].selectize;
            exhibitID = div.querySelector(".exhibit-id")
            exhibitName = div.querySelector(".exhibit-name")
          resolve(exhibit, startDate, endDate, exhibitOrg, exhibitStartMonth, exhibitEndMonth, exhibitEndYear, exhibitID, exhibitOrg);
        } else {
          var reason = new Error("I'm not working");
          reject(reason);
         }
       });
        var pleaseSetTheDiv = function() {
          settingTheDiv.then(function(response){
          })
          .catch(function(error){
            console.log(error.message)
          });
        };
        getExhibitDetails(exhibit).then(function(result) {
          setExhibitDetails(result, exhibit, exhibitOrg, exhibitStartMonth, exhibitStartYear, exhibitEndMonth, exhibitEndYear, exhibitID, exhibitName);
          });
      }
    }
  });

  function setExhibitDetails(result, exhibit, exhibitOrg, exhibitStartMonth, exhibitStartYear, exhibitEndMonth, exhibitEndYear, exhibitID, exhibitName) {
    if (result.length > 0) {
      var org = result[0].organisation_id
      var start_date = null;
      if (result[0].start_date) {
        start_date = result[0].start_date.split('-')
        exhibitStartMonth.setValue(parseInt(start_date[1]))
        exhibitStartYear.setValue(start_date[0])
      } else {
        exhibitStartMonth.clear();
        exhibitStartYear.clear();
      };
      var end_date = null;
      if (result[0].end_date) {
        end_date = result[0].end_date.split('-')
        exhibitEndMonth.setValue(parseInt(end_date[1]))
        exhibitEndYear.setValue(end_date[0])
      } else {
        exhibitEndMonth.clear()
        exhibitEndYear.clear()
      }
      exhibitOrg.setValue(org);
      $(exhibitID).val(result[0].id);
      $(exhibitName).val(result[0].name);
    } else {
      exhibit.clear();
      exhibitOrg.clear();
      exhibitStartMonth.clear()
      exhibitStartYear.clear()
      exhibitEndMonth.clear()
      exhibitEndYear.clear()
      $(exhibitID).val("");
      $(exhibitName).val("");
    }
  };

  async function getExhibitDetails(exhibit) {
    try {
      const results = await $.ajax({
        url:  "/exhibitions/" + exhibit.$input[0].value,
        type: "GET",
        success: function(response) {
          console.log(response);
          if ((response.length == 1) && (response[0].id)) {
            response
          }
        }
      })
      return results
    } catch(error) {
      console.log(error);
    }
  };

});
