document.addEventListener("turbolinks:load", function() {
  $input = $("[data-behavior='search']")

  var options = {
    getValue: "name",
    url: function(phrase) {
      return "/search.json?q=" + phrase;
    },
    requestDelay: 300,
    categories: [
      {
        listLocation: "artworks",
        header: "<p class='search-header'>Artworks</p>",
      },
      {
        listLocation: "artists",
        header: "<p class='search-header'>Artist</p>",
      },
      {
        listLocation: "people",
        header: "<p class='search-header'>People</p>",
      },
      {
        listLocation: "orgs",
        header: "<p class='search-header'>Organisations</p>",
      }
    ],
    list: {
      maxNumberOfElements: 12,
      sort: { enabled: true },
      onChooseEvent: function() {
        var url = $input.getSelectedItemData().url
        $input.val("")
        Turbolinks.visit(url)
      }
    }
  }

  $input.easyAutocomplete(options)

})
