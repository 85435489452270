$(document).on("turbolinks:load", function() {

  $(".selectize-owner").selectize({
    sortField: 'text',
    lockOptgroupOrder: true,
  });

  $(".selectize-org-exhibition").selectize({
    placeholder: "Select organisation",
    sortField: 'text'
  });

  $(".selectize-editions").selectize();

  $(".selectize-owner-date").selectize({
    maxItems: '1',
    highlight: false,
  });

  const ownerPriceOptions = {
    minimumValue: 0,
    maximumValue: 9999999999999,
    decimalPlacesOverride: 0,
    unformatOnSubmit: true,
    watchExternalChanges: true,
  }

  $('.owner-price').autoNumeric('init', ownerPriceOptions);
  $('.auction-low-estimate').autoNumeric('init', ownerPriceOptions);
  $('.auction-high-estimate').autoNumeric('init', ownerPriceOptions);

  let allSales = document.querySelectorAll('.sales')
  var theDivs = []
  for (var i=0; i < allSales.length; i++) {
    theDivs.push(allSales[i])
  }

  allSales.forEach(element => saleDetails(element))

  function toggleDetails(saleType, saleDiv, org, auctionDiv, auction, location, lotNr, loEst, hiEst, deleteSale) {
    if (saleType.val() == "Auction") {
      org.clear()
      saleDiv.hide()
      auctionDiv.show()
      deleteSale.val(false)
    } else if (saleType.val() == "Private Sale") {
      auction.clear()
      location.clear()
      lotNr.val("")
      loEst.autoNumeric('wipe')
      hiEst.autoNumeric('wipe')
      deleteSale.val(false)
      saleDiv.show();
      auctionDiv.hide();
    } else {
      org.clear()
      auction.clear()
      location.clear()
      lotNr.val("")
      loEst.val("")
      hiEst.val("")
      saleDiv.hide();
      auctionDiv.hide();
      deleteSale.val(true)
      }
  }

  function saleDetails(element) {
    let saleType = $(element).find('.selectize-sale-type').selectize({placeholder: "Select type of sale"})
    let saleDiv = $(element).find('.private-sale')
    let saleOrg = $(element).find('.selectize-sale-org').selectize({placeholder: "Select org"});
    let org = saleOrg[0].selectize;
    let auctionDiv = $(element).find('.auction')
    let auctionHouse = $(element).find('.selectize-auction-house').selectize({placeholder: "Select auction house"})
    let auction = auctionHouse[0].selectize;
    let auctionLocation = $(element).find('.selectize-auction-location').selectize({placeholder: "Select city"})
    let location = auctionLocation[0].selectize;
    let lotNr = $(element).find('.lot-nr')
    let loEst = $(element).find('.auction-low-estimate')
    let hiEst = $(element).find('.auction-high-estimate')
    let deleteSale = $(element).find('.delete-sale')
    toggleDetails(saleType, saleDiv, org, auctionDiv, auction, location, lotNr, loEst, hiEst, deleteSale)
    saleType.on("change", function() {
      toggleDetails(saleType, saleDiv, org, auctionDiv, auction, location, lotNr, loEst, hiEst, deleteSale)
    })
  }

  $('#owners').on('cocoon:after-insert', function(e, addedItem) {
    $(addedItem).find($('.owner-price')).autoNumeric('init', ownerPriceOptions);
    $(addedItem).find($('.selectize-owner-date')).selectize({ maxItems: '1'});
    $(addedItem).find($('.selectize-editions')).selectize();
    saleDetails(addedItem)

    var newOwner = $(addedItem).find($(".selectize-owner")).selectize({
      sortField: 'text',
      lockOptgroupOrder: true,
    });

    $.ajax({
      method: "GET",
      url: "/owners",
      success: function(response) {
        selector = newOwner[0].selectize
        selector.clearOptions();
        var i;
        var gid = null
        for (i = 0; i < response.length; i++) {
          if (response[i].typeName == "Person") {
            gid = "gid://arti-fact/Person/" + response[i].person_id
          } else {
            gid = "gid://arti-fact/Organisation/" + response[i].organisation_id
          };
          selector.addOption({optgroup: response[i].typeName, value: gid, text: response[i].name});
          gid = null;
        }
      }
    });

    $('.disable-submit').on('keypress', e => {
        if (e.keyCode == 13) {
            return false;
        }
    });

  })

  function addNewOwner(response, type) {
    console.log(response);
    var items = document.querySelectorAll(".selectize-owner")
    for (var i=0; i < items.length; i+= 3) {
      theItem = items[i].selectize
      if (type == "Person") {
        name = response.first_name + " " + response.last_name
        gid = "gid://arti-fact/Person/" + response.id
        theItem.addOption({optgroup: "Person", value: gid, text: name})
      } else if (type == "Org" ) {
        gid = "gid://arti-fact/Organisation/" + response.id
        theItem.addOption({optgroup: "Organisation", value: gid, text: response.name});
        console.log(theItem);
      } else {
        console.log(error);
      }
    }
  }

  function addNewOrg(response) {
    var items = document.querySelectorAll(".selectize-org-exhibition")
    for (var i=0; i < items.length; i+= 3) {
      theItem = items[i].selectize
      theItem.addOption({text: response.name, value: response.id.toString()});
    }
  }

  function addNewOrgLit(response) {
    var items = document.querySelectorAll(".selectize-lit-org")
    for (var i=0; i < items.length; i+= 3) {
      theItem = items[i].selectize
      theItem.addOption({text: response.name, value: response.id.toString()});
    }
  }

  // add person button

  $("#add-person-btn").on('click', function() {
    $(".new-person-modal").modal('show');
  });

  $("#new_person").on("submit", function(e){
    var ownerSelection = $(".selectize-owner")[0].selectize;
    e.preventDefault();
    $.ajax({
      method: "POST",
      url: $(this).attr("action"),
      data: $(this).serialize(),
      success: function(response) {
        addNewOwner(response, "Person");
        $(".new-person-modal").modal('hide');
      }
    });
  });

  $(".new-person-modal").on("hide.bs.modal", function(e) {
    $("#new_person").trigger("reset");
    var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
    $(selectors).each(function() {
      $.rails.enableElement(this);
    })
  });

  $("#address_container").show();
  $("#new-person-modal-org").show();

  $("#new-person-modal-org-selector").on("change", function() {
    if ($("#new-person-modal-org-selector").val()) {
      $("#address_container").hide();
    } else {
      $("#address_container").show();
    }
   })

  // add org button

  $(".add-org-btn").on('click', function() {
    $(".new-org-owner-modal").modal('show');
  });

  $("#new-org-owner-modal").on("submit", function(e){
    e.preventDefault();
    $.ajax({
      method: "POST",
      url: $(this).attr("action"),
      data: $(this).serialize(),
      success: function(response) {
        console.log(response);
        addNewOwner(response, "Org");
        addNewOrg(response);
        addNewOrgLit(response);
        $(".new-org-owner-modal").modal('hide');
      }
    });
  });

  $(".new-org-owner-modal").on("hide.bs.modal", function(e) {
    $("#new_organisation").trigger("reset");
    var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
    $(selectors).each(function() {
      $.rails.enableElement(this);
    })
  });

});
