$(document).on("turbolinks:before-cache", function() {
  let selectize_items = document.querySelectorAll(".selectize")
});

$(document).on("turbolinks:load", function() {

  $('body').scrollspy({
    target: '#artwork-editing-nav',
    offset: 175,
  });

  $('.nav li a').click(function(event) {
      event.preventDefault();
      setActiveNav(event);
      clickID = event.target.hash
      target = event.target.hash + "-container";
      toggleField(clickID, target)
  });

  function setActiveNav(event) {
    let navPills = document.querySelectorAll('.nav-link')
    navPills.forEach(element => element.className = "nav-link" )
    event.target.className = "nav-link active"
  }

  function toggleField(clickID, target) {
    const allFields = [
      ["#artwork-details", "#artwork-details-container"],
      ["#artwork-images", "#artwork-images-container"],
      ["#artwork-editions", "#artwork-editions-container"],
      ["#artwork-provenance", "#artwork-provenance-container"],
      ["#artwork-exhibitions", "#artwork-exhibitions-container"],
      ["#artwork-literature", "#artwork-literature-container"],
      ["#artwork-notes", "#artwork-notes-container"]
    ]
      var i
      for (i = 0; i < allFields.length; i++) {
        if (allFields[i][0] == clickID) {
         $(target).show(800, function() {
           setNotesHeight(clickID)
         });
       } else {
         $(allFields[i][1]).hide();
       }
     }
   }

   function setNotesHeight(clickID) {
     let notes = document.querySelectorAll(".notes-area")
     if ((clickID == "#artwork-editions") || (clickID == "#artwork-notes") ) {
       if (notes != null) {
         notes.forEach(element =>  {
           setHeight(element.id)
         });
       }
     }
   }

   function setHeight(fieldId){
     note = document.getElementById(fieldId)
     note.style.height = note.scrollHeight + 'px'
   }

   $(".notes-area").on('input', function (e) {
     setHeight(e.target.id)
   });

// general selectize

  $(".selectize").selectize();

  var selectizeInscriptionParams = {
    persist: true,
    placeholder: "Select inscription or add a new one",
    sortField: 'text',
    create: true,
  };

  $(".selectize-inscription").selectize(selectizeInscriptionParams);

  $(".selectize-filter-keywords").selectize({
    dropdownParent: 'body',
    selectOnTab: true,
    sortField: 'text'
  })

  $(".selectize-filter-years").selectize({
    maxItems: '1',
    selectOnTab: true,
    dropdownParent: 'body'
  });

  $(".selectize-filter-org-type").selectize({
    maxItems: '1',
    dropdownParent: 'body',
    placeholder: "Type",
    sortField: 'text'
  })

  $(".selectize-filter-org-name").selectize({
    dropdownParent: 'body',
    placeholder: "Name",
    sortField: 'text'
  })


});

// select an artist

$(document).on("turbolinks:load", function() {

  var selectizeCallback = null;

  $(".selectize-artist").selectize({
    create: function(input, callback) {
      selectizeCallback = callback;
      $(".new-artist-modal").modal('show');
      var full_name = input.split(" ")
      $("#artist_first_name").val(full_name[0]);
      $("#artist_last_name").val(full_name[1]);
    }
  });

  $("#new_artist").on("submit", function(e) {
    e.preventDefault();
    $.ajax({
      method: "POST",
      url: $(this).attr("action"),
      data: $(this).serialize(),
      success: function(response) {
        var full_name = response.first_name + " " + response.last_name
        selectizeCallback({value: response.id, text: full_name});
        selectizeCallback = null;
        $(".new-artist-modal").modal('hide');
      }
    });
  });

  $(".new-artist-modal").on("hide.bs.modal", function(e) {
    if (selectizeCallback != null) {
      selectizeCallback();
      selecitzeCallback = null;
    }
    $("#new_artist").trigger("reset");
    var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
    $(selectors).each(function() {
      $.rails.enableElement(this);
    })
  });

});

// select keywords

$(document).on("turbolinks:load", function() {

  var selectizeCallback = null;

  $(".selectize-keywords").selectize({
    delimiter: ',',
    persist: true,
    placeholder: "e.g. still life, portrait",
    sortField: 'text',
    create: function(input,callback) {
      selectizeCallback = callback;
      $.ajax({
        method: "POST",
        url: "/keywords",
        data: {keyword: {name: input}},
        success: function(response) {
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
        }
      });
    }
  });

});

// select medium

$(document).on("turbolinks:load", function() {

  var selectizeCallback = null;

  $(".selectize-medium").selectize({
    delimiter: ',',
    persist: true,
    placeholder: "e.g. oil on canvas",
    sortField: 'text',
    create: function(input,callback) {
      selectizeCallback = callback;
      $.ajax({
        method: "POST",
        url: "/media",
        data: {medium: {name: input}},
        success: function(response) {
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
        }
      });
    }
  });

});
