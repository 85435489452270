$(document).on("turbolinks:load", function() {

  //selectize params

  var selectizeDateParams = {
    maxItems: '1',
  }

  var selectizeCallback = null;

  var selectizeLiteratureParams = {
    persist: true,
    placeholder: "Select literature",
    sortField: 'text',
    create: function(input,callback) {
      selectizeCallback = callback;
      $.ajax({
        method: "POST",
        url: "/literatures/",
        data: {literature: {name: input}},
        success: function(response) {
          console.log(response);
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
        }
      });
    }
  };

  var selectizeLitTypeParams = {
    persist: true,
    sortField: 'text',
    create: true,
  };

  var selectizeOrgLitParams = {
      persist: true,
      placeholder: "Select organisation",
      sortField: 'text',
  }

  var selectizePublicationParams = {
    placeholder: "Select publication",
    sortField: 'text',
    create: true,
  };

  var selectizeCallback = null;
  var selectizeLitCityParams = {
    persist: true,
    placeholder: "Select city",
    sortField: 'text',
    create: function(input,callback) {
      selectizeCallback = callback;
      $.ajax({
        method: "POST",
        url: "/cities/",
        data: {city: {name: input}},
        success: function(response) {
          console.log(response);
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
        }
      });
    }
  };

  var selectizeAuthorParams = {
    persist: true,
    placeholder: "Select author",
    sortField: 'text',
    create: true,
  };

  // on startup

  function selectizeLits() {
    $(".selectize-lit-date").selectize(selectizeDateParams)
    $(".selectize-lit-type").selectize(selectizeLitTypeParams)
    $(".selectize-lit-publication").selectize(selectizePublicationParams)
    $(".selectize-lit-city").selectize(selectizeLitCityParams)
    $(".selectize-lit-org").selectize(selectizeOrgLitParams)
    $(".selectize-lit-author").selectize(selectizeAuthorParams)
    $(".selectize-literature").selectize(selectizeLiteratureParams);
  }

  selectizeLits();

  var items = document.querySelectorAll(".selectize-literature")
  for (var i=0; i < items.length; i+= 3) {
    var theItem = items[i].selectize
    theItem.clearOptions()
    theItem.load(function(callback){
      getAllLit(callback)
    })
  };

  function getAllLit(callback) {
    $.ajax({
      method: "GET",
      url: "/literatures",
      success: function(response) {
        callback(response)
      },
      error: function(error) {
        console.log(error);
        callback();
      }
    })
  }

  // adding new literature

  $('#artwork_literatures').on('cocoon:after-insert', function(e, addedItem) {

    var newLit = $(addedItem).find($(".selectize-literature")).selectize(selectizeLiteratureParams);
    var newLitDate = $(addedItem).find($(".selectize-lit-date")).selectize({maxItems: '1'});
    var newLitType = $(addedItem).find($(".selectize-lit-type"))
    var newLitPub = $(addedItem).find($(".selectize-lit-publication"))
    var newLitOrg = $(addedItem).find($(".selectize-lit-org"))
    var newLitCity = $(addedItem).find($(".selectize-lit-city"))
    var newLitAuthor = $(addedItem).find($(".selectize-lit-author"))

    newLitPub.selectize(selectizePublicationParams)
    newLitOrg.selectize(selectizeOrgLitParams)
    newLitType.selectize(selectizeLitTypeParams)
    newLitCity.selectize(selectizeLitCityParams)
    newLitAuthor.selectize(selectizeAuthorParams)

    var lit = newLit[0].selectize;
    var litYear = newLitDate[0].selectize;
    var litType = newLitType[0].selectize;
    var litCity = newLitCity[0].selectize;
    var litPub = newLitPub[0].selectize;
    var litOrg = newLitOrg[0].selectize;
    var litAuthor = newLitAuthor[0].selectize;
    var litID = $(addedItem).find($(".lit-id"));
    var litName = $(addedItem).find($(".lit-name"));

    lit.clearOptions();
    lit.load(function(callback){
      getAllLit(callback)
    });

    $('.disable-submit').on('keypress', e => {
        if (e.keyCode == 13) {
            return false;
        }
    });

    $(addedItem).find($(".selectize-literature")).on("change", function() {
      getLitDetails(lit).then(function(result) {
        setLitDetails(result, lit, litYear, litType, litCity, litPub, litOrg, litAuthor, litID, litName);
      })
    })
  })

  //on change of literature

  var allLits = document.querySelectorAll(".literature")
  var theDivs = []
  for (var i=0; i < allLits.length; i++) {
    theDivs.push(allLits[i])
  }

  $(".selectize-literature").on("change", function(e) {
    var lit = null
    var litDate = null
    var litYear = null
    var litType = null
    var litCity = null
    var litPub = null
    var litOrg = null
    var litAuthor = null
    var litID = null
    var litName = null;
    for (var i=0; i < theDivs.length; i++ ) {
      if (e.target.name == $(theDivs[i]).find(".selectize-literature")[0].name) {
        var div = theDivs[i]
        var settingTheDiv = new Promise(
          function(resolve, reject) {
            if (div != null) {
            lit = div.querySelector(".selectize-literature").selectize
            litDate = div.querySelectorAll(".selectize-lit-date")
            litYear = litDate[0].selectize;
            litType = div.querySelector(".selectize-lit-type").selectize
            litCity = div.querySelector(".selectize-lit-city").selectize
            litPub = div.querySelector(".selectize-lit-publication").selectize
            litOrg = div.querySelector(".selectize-lit-org").selectize
            litAuthor = div.querySelector(".selectize-lit-author").selectize
            litID = div.querySelector(".lit-id")
            litName = div.querySelector(".lit-name")
          resolve(lit, litDate, litYear, litType, litCity, litPub, litOrg, litAuthor, litID);
        } else {
          var reason = new Error("I'm not working");
          reject(reason);
         }
       });
        var pleaseSetTheDiv = function() {
          settingTheDiv.then(function(response){
          })
          .catch(function(error){
            console.log(error.message)
          });
        };
        getLitDetails(lit).then(function(result) {
          setLitDetails(result, lit, litYear, litType, litCity, litPub, litOrg, litAuthor, litID, litName);
          });
      }
    }
  });

  function setLitDetails(result, lit, litYear, litType, litCity, litPub, litOrg, litAuthor, litID, litName) {
    if (result.length > 0) {
      console.log(result);
      if (result[0].lit_type) {
        litType.setValue(result[0].lit_type);
      } else {
        litType.clear();
      };
      if (result[0].city_id) {
        litCity.setValue(result[0].city_id);
      } else {
        litCity.clear();
      };
      if (result[0].publication) {
        litPub.setValue(result[0].publication)
      } else {
        litPub.clear();
      }
      if (result[0].organisation_id) {
        litOrg.setValue(result[0].organisation_id)
      } else {
        litOrg.clear();
      }
      if (result[0].author) {
        litAuthor.setValue(result[0].author);
      } else {
        litAuthor.clear();
      }
      var date = null;
      if (result[0].date) {
        date = result[0].date.split('-')
        litYear.setValue(date[0])
      } else {
        litYear.clear();
      };
      $(litID).val(result[0].id);
      $(litName).val(result[0].name);
    } else {
      lit.clear();
      litYear.clear();
      litType.clear();
      litCity.clear();
      litPub.clear();
      litOrg.clear();
      litAuthor.clear();
      $(litID).val("");
      $(litName).val("");
    }
  };

  async function getLitDetails(lit) {
    try {
      const results = await $.ajax({
        url:  "/literatures/" + lit.$input[0].value,
        type: "GET",
        success: function(response) {
          console.log(response);
          if ((response.length == 1) && (response[0].id)) {
            response
          }
        }
      })
      return results
    } catch(error) {
      console.log(error);
    }
  };

});
