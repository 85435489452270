const Uppy= require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload')


require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-uppy]').forEach(element => startUppy(element))
})

function startUppy(element) {
  let trigger = "#uppy-trigger"
  let uppyTrigger = document.querySelector(uppyTrigger)
  let form = element.closest('form')
  let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  let field_name = element.dataset.uppy

  if (uppyTrigger) {
    startUppy()
  }

  let uppy = new Uppy({
    autoProceed: true,
    logger: Uppy.debugLogger
  })

  uppy.use(ActiveStorageUpload, {
    directUploadUrl: direct_upload_url
  })

  uppy.use(Dashboard, {
    inline: true,
    target: "#uppy-dashboard",
    height: 400,
    proudlyDisplayPoweredByUppy: false,
    showProgressDetails: true,
  })

  uppy.on('complete', (result) => {

    result.successful.forEach(file => {
      appendUploadedFile(element, file, field_name)
    })
  })
}

function appendUploadedFile(element, file, field_name) {
  const hiddenField = document.createElement('input')
  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', field_name)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute('value', file.response.signed_id)

  element.appendChild(hiddenField)

}
