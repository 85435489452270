$(document.addEventListener("turbolinks:load",function() {

// Creating new org

   var selectizeCallback = null;

   $(".selectize-org").selectize({
     persist: true,
     placeholder: "Select an organisation",
     sortField: 'text',
     create: function(input, callback) {
       selectizeCallback = callback;
       $(".new-org-modal").modal('show');
       $("#org_name").val(input);
     }
    });

    $("#new-org-modal").on("submit", function(e) {
      e.preventDefault();
      $.ajax({
        method: "POST",
        url: $(this).attr("action"),
        data: $(this).serialize(),
        success: function(response) {
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
          $(".new-org-modal").modal('hide');
        }
      });
    });

    $(".new-org-modal").on("hide.bs.modal", function(e) {
      if (selectizeCallback != null) {
        selectizeCallback();
        selecitzeCallback = null;
      }
      $("#new_organisation").trigger("reset");
      var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
      $(selectors).each(function() {
        $.rails.enableElement(this);
      })
    });

    $(".selectize-filter-orgs").selectize({
      dropdownParent: 'body',
      sortField: 'text'
    })

// updating address on org selected

  function getOrgAddress() {
    $("#address_container").hide();
    $.ajax({
      url:  "/addresses/",
      type: "GET",
      data: { id: $("#org_selector").val() },
      success: function(response) {
        $("#org_address_container").show();
        if (response === undefined || response.length == 0) {
          $("#no_org_address").show();
          $("#org_address").hide();
        }
        else {
          $("#org_city").text(response[0].city);
          if (response[0].state) {
            $("#org_state").text(response[0].state);
          } else {
            $("#org_state").text("--");
          }
          $("#org_country").text(response[0].country);
          $("#no_org_address").hide();
          $("#org_address").show();
        }
      }
    })
  }

  function hideOrgAddress() {

    var theCity = $('.selectize-cities')[0].selectize
    var theState = $('.selectize-states')[0].selectize
    var theCountry = $('.selectize-countries')[0].selectize

    $("#address_container").show();
    $("#org_address_container").hide();
    theCity.clear();
    theState.clear();
    theCountry.clear();
  }

  if ($("#org_selector").val()) {
    getOrgAddress();
  } else {
    $("#address_container").show();
    $("#org_address_container").hide();
  }

  $("#org_selector").on("change", function() {
    if ($("#org_selector").val()) {
      getOrgAddress();
    } else {
      hideOrgAddress();
    }
  });

// Adding in new city

  var selectizeCallback = null;

  $(".selectize-cities").selectize({
    persist: true,
    placeholder: "Select city or type in a new one",
    sortField: 'text',
    create: function(input,callback) {
      selectizeCallback = callback;
      $.ajax({
        method: "POST",
        url: "/cities/",
        data: {city: {name: input}},
        success: function(response) {
          console.log(response);
          selectizeCallback({value: response.id, text: response.name});
          selectizeCallback = null;
        }
      });
    }
   });

   $(".selectize-filter-cities").selectize({
     dropdownParent: 'body',
     sortField: 'text'
   })

// Selectize countries

  $(".selectize-countries").selectize({
    persist: true,
    sortField: 'text',
    dropdownParent: 'body',
   });

 // Selectize states

   $(".selectize-states").selectize({
     persist: true,
     sortField: 'text',
    });

// Hiding state except when US selected

     $("#country_selector").on("change", function() {

       var selectedCountry = $('#country_selector')

       if (selectedCountry.val() == "United States") {
         $('#us_states').show();
       } else if (selectedCountry.val() != "United States") {
         $('#us_states').hide();
       }
     });

     var selectedCountry = $('#country_selector')

     if (selectedCountry.val() == "United States") {
       $('#us_states').show();
     } else if (selectedCountry.val() != "United States") {
       $('#us_states').hide();
     }

// Hiding addresses when org selected


 })
)
